/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {

                /*! modernizr 3.5.0 (Custom Build) | MIT *
                 * https://modernizr.com/download/?-touchevents-setclasses !*/
                !function (e, n, t) {
                    function o(e, n) {
                        return typeof e === n
                    }

                    function s() {
                        var e, n, t, s, a, i, r;
                        for (var l in c)if (c.hasOwnProperty(l)) {
                            if (e = [], n = c[l], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length))for (t = 0; t < n.options.aliases.length; t++)e.push(n.options.aliases[t].toLowerCase());
                            for (s = o(n.fn, "function") ? n.fn() : n.fn, a = 0; a < e.length; a++)i = e[a], r = i.split("."), 1 === r.length ? Modernizr[r[0]] = s : (!Modernizr[r[0]] || Modernizr[r[0]] instanceof Boolean || (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])), Modernizr[r[0]][r[1]] = s), f.push((s ? "" : "no-") + r.join("-"))
                        }
                    }

                    function a(e) {
                        var n = u.className, t = Modernizr._config.classPrefix || "";
                        if (h && (n = n.baseVal), Modernizr._config.enableJSClass) {
                            var o = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
                            n = n.replace(o, "$1" + t + "js$2")
                        }
                        Modernizr._config.enableClasses && (n += " " + t + e.join(" " + t), h ? u.className.baseVal = n : u.className = n)
                    }

                    function i() {
                        return "function" != typeof n.createElement ? n.createElement(arguments[0]) : h ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0]) : n.createElement.apply(n, arguments)
                    }

                    function r() {
                        var e = n.body;
                        return e || (e = i(h ? "svg" : "body"), e.fake = !0), e
                    }

                    function l(e, t, o, s) {
                        var a, l, f, c, d = "modernizr", p = i("div"), h = r();
                        if (parseInt(o, 10))for (; o--;)f = i("div"), f.id = s ? s[o] : d + (o + 1), p.appendChild(f);
                        return a = i("style"), a.type = "text/css", a.id = "s" + d, (h.fake ? h : p).appendChild(a), h.appendChild(p), a.styleSheet ? a.styleSheet.cssText = e : a.appendChild(n.createTextNode(e)), p.id = d, h.fake && (h.style.background = "", h.style.overflow = "hidden", c = u.style.overflow, u.style.overflow = "hidden", u.appendChild(h)), l = t(p, e), h.fake ? (h.parentNode.removeChild(h), u.style.overflow = c, u.offsetHeight) : p.parentNode.removeChild(p), !!l
                    }

                    var f = [], c = [], d = {
                        _version: "3.5.0",
                        _config: {classPrefix: "", enableClasses: !0, enableJSClass: !0, usePrefixes: !0},
                        _q: [],
                        on: function (e, n) {
                            var t = this;
                            setTimeout(function () {
                                n(t[e])
                            }, 0)
                        },
                        addTest: function (e, n, t) {
                            c.push({name: e, fn: n, options: t})
                        },
                        addAsyncTest: function (e) {
                            c.push({name: null, fn: e})
                        }
                    }, Modernizr = function () {
                    };
                    Modernizr.prototype = d, Modernizr = new Modernizr;
                    var u = n.documentElement,
                        p = d._config.usePrefixes ? " -webkit- -moz- -o- -ms- ".split(" ") : ["", ""];
                    d._prefixes = p;
                    var h = "svg" === u.nodeName.toLowerCase(), m = d.testStyles = l;
                    Modernizr.addTest("touchevents", function () {
                        var t;
                        if ("ontouchstart" in e || e.DocumentTouch && n instanceof DocumentTouch) t = !0; else {
                            var o = ["@media (", p.join("touch-enabled),("), "heartz", ")", "{#modernizr{top:9px;position:absolute}}"].join("");
                            m(o, function (e) {
                                t = 9 === e.offsetTop
                            })
                        }
                        return t
                    }), s(), a(f), delete d.addTest, delete d.addAsyncTest;
                    for (var v = 0; v < Modernizr._q.length; v++)Modernizr._q[v]();
                    e.Modernizr = Modernizr
                }(window, document);


                    /* END */


                // JavaScript Document
                var sayThis = function (target) {
                    /*
                     console.log(target);
                     */
                };

                //Image Background Exchanger
                function set_cover_image(target) {
                    var ww = $(window).width(),
                        wh = $(window).height();

                    var tabletBreakpoint = 1200;
                    var mobileBreakpoint = 767;

                    $(target).each(function (index) {
                        var imgsrc = $(this).attr('data-desktopimg');


                        if (ww < tabletBreakpoint && ww > mobileBreakpoint) {


                            var tabletimgp = $(this).attr('data-tabletimgp');
                            if (tabletimgp) {
                                imgsrc = tabletimgp;
                                sayThis('Using Portrait Tablet Image');
                            }

                            if (ww > wh || !tabletimgp) {
                                var tabletimg = $(this).attr('data-tabletimg');
                                if (tabletimg) {
                                    imgsrc = tabletimg;
                                    sayThis('Using Landscape Tablet Image');
                                }
                            }
                        }
                        else if (ww < mobileBreakpoint) {

                            var mobileimgp = $(this).attr('data-mobileimgp');
                            sayThis("mobileimgp " + mobileimgp);

                            if (mobileimgp) {
                                imgsrc = mobileimgp;
                                sayThis('Using Portrait Mobile Image');
                            }

                            var mobileimg = $(this).attr('data-mobileimg');
                            sayThis("mobileimg " + mobileimg);

                            if (ww > wh || !mobileimgp) {
                                if (mobileimg !== "") {
                                    imgsrc = mobileimg;
                                    sayThis('Using Landscape Mobile Image');
                                }
                            }


                        }

                        if (imgsrc) {
                            if ($(this).css('background-image') !== imgsrc) {
                                $(this).css('background-image', 'url("' + imgsrc + '")');
                            }
                        }

                        if (imgsrc === $(this).attr('data-desktopimg')) {
                            sayThis('Using Desktop Image');
                        }
                    });
                }

                $(window).resize(function () {
                    set_cover_image('.bkg_container');
                });

                $(document).ready(function () {
                    set_cover_image('.bkg_container');
                });


            },
            finalize: function () {

                function toggleMenu(e) {
                    e.preventDefault();
                    $(".nav__menu").toggleClass("open");
                    $("body").toggleClass("menu-open");
                }


                var ua = navigator.userAgent,
                    isMobileWebkit = /WebKit/.test(ua) && /Mobile/.test(ua);

                if (isMobileWebkit) {
                    $('html').addClass('mobile');


                    $(".nav-toggle").on({ 'touchstart' : toggleMenu });
                    $(".nav__menu-toggle").on({ 'touchstart' : toggleMenu });

                }else{
                    $(".nav-toggle").click(toggleMenu);
                    $(".nav__menu-toggle").click(toggleMenu);
                }


                /* Mobile Dropdowns
                ===============================  */
                $(".menu-item-has-children").click(function (e) {
                    $(this).toggleClass("open");
                });



                /*Scrolls the browser to the top
                 ========================================= */
                function scrollToTop() {
                    $("html, body").animate({
                        scrollTop: 0
                    }, 600);
                    return false;
                }

                $("#scrolltop").click(scrollToTop);


            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

                $('.news-slider').slick({
                    fade: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    verticalswipe: true,
                    arrows: false,

                });

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        }


    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
